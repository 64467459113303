import React from 'react'
import styles from './HeaderSection.module.css'
import logo from 'assets/logo.svg'
import RectangleButton from 'components/RectangleButton/RectangleButton'
import { classNames } from 'utils'

const items = ["Home", "Services", "Clients", "Insights", "Contact"]

interface HeaderSectionProps {
  className?: string
}

const HeaderSection = ({ className }: HeaderSectionProps) => (
  <header className={classNames(styles.container, className)}>
    <img className={styles.logo} src={logo} alt="Aspensource" onClick={() => window.location.reload()} />
    <nav className={styles.navbar}>
      <ul>
        { items.map(item => <li><a href={'#' + item}>{item}</a></li>) }
      </ul>
    </nav>
    <RectangleButton title='Get in Touch' />
  </header>
)

export default HeaderSection;