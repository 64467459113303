import React from 'react'
import styles from './CircleButton.module.css'

interface CircleButtonProps {
    href?: string
}

const CircleButton = ({ href } : CircleButtonProps) => (
    <a className={styles.container} href={href}>
        <i className='fa-solid fa-arrow-right-long'></i>
    </a>
)

export default CircleButton;