import './HomePage.css'
import React from 'react';
import HeroSection from 'sections/HeroSection/HeroSection';
import ServiceSection from 'sections/ServiceSection/ServiceSection';
import DetailedServiceSection from 'sections/DetailedServiceSection/DetailedServiceSection';
import ContactSection from 'sections/ContactSection/ContactSection';
import ClientSection from 'sections/ClientSection/ClientSection';
import InsightSection from 'sections/InsightSection/InsightSection';

//  <DetailedServiceSection />
//     <ProcessSection />
// <ExclusiveServiceSection />

const HomePage = () => (
  <>
    <HeroSection />
    <ServiceSection className='service-section' />
    <DetailedServiceSection className='detailed-service-section'  />
    <ClientSection />
    <InsightSection />
    <ContactSection className='contact-section' />
  </>
)

export default HomePage;