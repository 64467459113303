import React from 'react'
import styles from './RectangleButton.module.css'
import { classNames } from 'utils';

interface RectangleButtonProps {
    className?: string;
    title: string;
    type?: 'submit' | 'reset' | 'button' | undefined;
}

const RectangleButton = ({ className, title, type }: RectangleButtonProps) => (
    <button className={classNames(styles.container, className)} type={type}>
        <a>{title}</a>
        <span><i className='fa-solid fa-arrow-right-long'></i></span>
    </button>
)

export default RectangleButton;