import React from 'react'
import styles from './ContactSection.module.css'
import { classNames } from 'utils';
import RectangleButton from 'components/RectangleButton/RectangleButton';

import { InlineWidget } from 'react-calendly';

interface ContactSectionProps {
  className?: string
}

const ContactSection = ({ className }: ContactSectionProps) => (
  <section className={classNames(styles.container, className)} id='Contact'>
    <div className={styles.content}>

      <div className={styles.calendar}>
        <h3>Schedule a meeting</h3>
        <InlineWidget url='https://calendly.com/kaspar-haavajoe/call-with-kaspar' styles={{ height: '900px', width: '700px', marginLeft: '-20px', marginRight: '15px' }} />
      </div>

      <div className={classNames(styles.form, styles.separator)}>
        <h3>Send us a message</h3>
        <form action="https://api.web3forms.com/submit" method="POST">

          <input type="hidden" name="access_key" value='f6f75414-b40e-4508-9c12-6f51b58b20c6' />

          <div className={styles.inputBox}>
            <label>Name</label>
            <input type='text' name='name' required />
          </div>

          <div className={styles.inputBox}>
            <label>Email</label>
            <input type='text' name='email' required />
          </div>

          <div className={styles.inputBox}>
            <label>Describe your idea</label>
            <textarea name="message" required autoComplete='off' />
          </div>

          <RectangleButton title='Send Now' type='submit' />
        </form>
      </div>

    </div>
  </section>
)

export default ContactSection;