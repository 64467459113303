import React from 'react'
import styles from './ClientSection.module.css'
import { classNames } from 'utils';

import logoBrightloom from 'assets/brightloom-logo.svg'
import logoTmobile from 'assets/t-mobile-logo.png'
import logoBinderr from 'assets/binderr-logo.svg'

interface ClientSectionProps {
  className?: string
}

const ClientSection = ({ className }: ClientSectionProps) => (
  <section className={classNames(styles.container, className)} id='Clients'>
    <div className={styles.content}>
      <h3>Trusted by</h3>

      <div className={styles.logos}>
        <div>
          <img src={logoBrightloom} />
        </div>
        <div>
          <img src={logoTmobile} />
        </div>
        <div>
          <img src={logoBinderr} />
        </div>
        <div className={styles.overlay} />
      </div>
    </div>
  </section>
)

export default ClientSection;