import React from 'react'
import styles from './FooterSection.module.css'
import { classNames } from 'utils'
import logo from 'assets/logowhite.svg'

const currentYear = new Date().getFullYear()

interface FooterSectionProps {
  className?: string
}

const FooterSection = ({ className }: FooterSectionProps) => (
  <footer className={classNames(styles.container, className)}>
    <div className={styles.content}>

      <div className={styles.logo}>
        <img src={logo} alt="Aspensource" />
        <p>Aspensource is a dynamic and innovative technology company dedicated to solutions.</p>
      </div>

      <div className={styles.contact}>
        <h3>Explore</h3>
        <ul>
          <li><a href='#Home'><i className='fa-solid fa-arrow-right' />Home</a></li>
          <li><a href='#Services'><i className='fa-solid fa-arrow-right' />Our Services</a></li>
          <li><a href='#Home'><i className='fa-solid fa-arrow-right' />Our Blogs</a></li>
          <li><a href='#Home'><i className='fa-solid fa-arrow-right' />FAQs</a></li>
        </ul>
      </div>

      <div className={styles.contact}>
        <h3>LinkedIn</h3>
        <ul>
          <li>
            <a href='https://www.linkedin.com/in/kaspar-haavajoe' target='_blank'>
              <img src='https://content.linkedin.com/content/dam/me/brand/en-us/brand-home/logos/In-Blue-Logo.png.original.png' />
            </a>
          </li>
        </ul>
      </div>

      <div className={styles.contact}>
        <h3>Contact Us</h3>
        <ul>
          <li><i className="fa-solid fa-globe"></i>Aspensource OÜ</li>
          <li><i className='fa-regular fa-envelope' />info@aspensource.org</li>
          <li><i className='fa-solid fa-phone' />(+372) 53049994</li>
          <li><i className='fa-solid fa-location-dot' />Püssirohu 4, Tallinn, Estonia</li>
        </ul>
      </div>

    </div>

    <div className={styles.copyright}>
      <p><i className='fa-regular fa-copyright' />{currentYear} Aspensource. All rights reserved.</p>
    </div>

  </footer>
)

export default FooterSection;