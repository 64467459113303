import React from 'react'
import styles from './ServiceSection.module.css'
import CircleButton from 'components/CircleButton/CircleButton'
import { classNames } from 'utils'

interface Service {
  name: string,
  description: string,
  icon: string
}

const services: Service[] = [
  {
    name: "Design",
    description: "UX/UI design for web and mobile applications",
    icon: "https://techbenextjs.vercel.app/_next/image?url=%2Fassets%2Fimg%2Ficon%2FserviceIcon1_1.png&w=48&q=75"
  },
  {
    name: "Development",
    description: "Web, backend, mobile development",
    icon: "https://techbenextjs.vercel.app/_next/image?url=%2Fassets%2Fimg%2Ficon%2FserviceIcon1_1.png&w=48&q=75"
  },
  {
    name: "Data",
    description: "Data architecture, platform, pipelines and analytics",
    icon: "https://techbenextjs.vercel.app/_next/image?url=%2Fassets%2Fimg%2Ficon%2FserviceIcon1_2.png&w=96&q=75"
  },
  {
    name: "Cloud & DevOps",
    description: "Design and implement scalable cloud architectures",
    icon: "https://techbenextjs.vercel.app/_next/image?url=%2Fassets%2Fimg%2Ficon%2FserviceIcon1_3.png&w=48&q=75"
  },
  {
    name: "AI & ML",
    description: "Building automated pipelines for model training and deployment",
    icon: "https://techbenextjs.vercel.app/_next/image?url=%2Fassets%2Fimg%2Ficon%2FserviceIcon1_4.png&w=48&q=75"
  }
]

interface ServiceSectionProps {
  className?: string
}

const ServiceSection = ({ className }: ServiceSectionProps) => (
  <section className={classNames(styles.container, className)}>
    <div className={styles.overlayBlocks} />
    <div className={styles.content}>

      { services.map(item => (
        <div className={styles.card}>
          <div className={styles.cardIconContainer}>
            <img src={item.icon} />
            <div className={styles.cardIconOverlay} />
          </div>
          <h3>{item.name}</h3>
          <p>{item.description}</p>
          <CircleButton href={'#' + item.name} />
        </div>
      ))}

    </div>
  </section>
)

export default ServiceSection;