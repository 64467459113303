import React from 'react'
import styles from './InsightSection.module.css'
import { classNames } from 'utils';

interface InsightArticle {
  thumbnail: string;
  title: string;
  author: string;
  tags: string[];
  link: string;
}

const articles: InsightArticle[] = [
  {
    thumbnail: 'https://cdn-images-1.medium.com/max/1024/1*Le0833w1L13Zpq8lUYNvbA.png',
    title: 'Metadata driven architecture for Data Lakehouse',
    author: 'Kaspar Haavajõe',
    tags: ['Kafka', 'Snowflake', 'Data Lakehouse'],
    link: 'https://medium.com/@kasparhaavajoe/metadata-driven-architecture-for-data-lakehouse-a9ec896b92da'
  },
  {
    thumbnail: 'https://cdn-images-1.medium.com/max/347/1*owXLX3_6PjlfO5Sko6xa2A.png',
    title: 'Digital Transformation',
    author: 'Kaspar Haavajõe',
    tags: ['Digital Transformation'],
    link: 'https://medium.com/@kasparhaavajoe/digital-transformation-414e9be7269f'
  }
]

interface InsightSectionProps {
  className?: string
}

const InsightSection = ({ className }: InsightSectionProps) => (
  <section className={classNames(styles.container, className)} id='Insights'>
    <div className={styles.content}>
      <h3>Insights</h3>
      <div className={styles.cardContainer}>

        { articles.map(article => (
          <div className={styles.card}>
            <img src={article.thumbnail} />
            <div className={styles.cardBody}>
              <h3><a href={article.link}>{article.title}</a></h3>
              <div className={styles.cardAuthor}>
                <i className="fa-solid fa-user"></i>
                <span>{article.author}</span>
              </div>
              <div className={styles.cardTags}>
                { article.tags.map(tag => (<span>{tag}</span>)) }
              </div>
              <a className={styles.cardMore} href={article.link}>Read More <i className="fa-solid fa-arrow-right"></i></a>
            </div>
          </div>
        ))}

      </div>
    </div>
  </section>
)

export default InsightSection;