import React from 'react'
import styles from './HeroSection.module.css'
import RectangleButton from 'components/RectangleButton/RectangleButton';
import { classNames } from 'utils';

interface HeroSectionProps {
  className?: string
}

const HeroSection = ({ className }: HeroSectionProps) => (
  <section className={classNames(styles.container, className)} id='Home'>
      <div className={classNames(styles.absolute, styles.image)} />
      <div className={classNames(styles.absolute, styles.overlay)} />
      <div className={classNames(styles.absolute, styles.content)} >
        <div>
          <h2>Digital Consultancy</h2>
          <h1>Build Digital Products</h1>
          <h1>For Your Business</h1>
          <p>From Digital Transformation to Building Digital Products for your Business</p>
          <RectangleButton title='Explore More' />
        </div>
      </div>
  </section>
)

export default HeroSection;