import './App.css';
import React from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import HomePage from 'pages/HomePage/HomePage';
import { routes } from 'routes';
import HeaderSection from 'sections/HeaderSection/HeaderSection';
import FooterSection from 'sections/FooterSection/FooterSection';

const SkeletonPage = () => (
  <div>
    <HeaderSection />
    <Outlet />
    <FooterSection />
  </div>
)

const router = createBrowserRouter([
  {
    path: routes.home,
    element: <SkeletonPage />,
    children: [
      {
        path: routes.home,
        element: <HomePage />
      }
    ]
  }
])

const App = () => <RouterProvider router={router} />


export default App;
