import React from 'react'
import styles from './DetailedServiceSection.module.css'
import { classNames } from 'utils'
import designImg from 'assets/design-img.jpg'
import developmentImg from 'assets/development-image.jpg'
import dataImg from 'assets/data-image.jpg'
import cloudImg from 'assets/cloud-image.jpg'
import aiImg from 'assets/ai-image.jpg'
import RectangleButton from 'components/RectangleButton/RectangleButton'

interface ServiceProps {
  left?: boolean
  title: string
  description: string
  steps: string[]
  image?: string
  buttonTitle: string
}

const Service = ({ left = true, title, description, steps, image, buttonTitle }: ServiceProps) => {
  const content = (
    <div className={styles.left}>
      <h1>{ title }</h1>
      <p>
        { description }
        <ul>
          { steps.map(item => <li><i className='fa-regular fa-circle-check' />{item}</li>) }
        </ul>
        <RectangleButton className={styles.serviceButton} title={buttonTitle} />
      </p>
    </div>
  )
  const imageContent = (
    <div className={styles.right}>
      { image && <img src={image} /> }
    </div>
  )

  return (
    <div className={styles.service} id={title}>
      { left ? <>{content}{imageContent}</> : <>{imageContent}{content}</>}
    </div>
  )
}

interface DetailedServiceSectionProps {
  className?: string
}

const DetailedServiceSection = ({ className }: DetailedServiceSectionProps) => (
  <section className={classNames(styles.container, className)} id='Services'>
    <div className={styles.content}>

      <Service
        title='Design'
        description='UX/UI Design service focuses on creating intuitive, user-centric design to enhance engagement and deliver seamless digital experiences. We design web and mobile applications that are visually appealing, easy to navigate, and tailored to your target audience. Whether building from scratch or refining existing interfaces, our goal is to align design with your business objectives and user needs.'
        steps={["Discovery", "Iterative process", "UI Design", "Prototyping"]}
        image={designImg}
        buttonTitle='Contact Here'
      />

      <Service
        title='Development'
        description='Software Development service is focused on creating tailored solutions for turning your vision into tangible digital solution. Web development focuses on modern, responsive and dynamic web platforms. Mobile development focuses on intuituve and feature-rich mobile cross-platform applications.'
        steps={["Analysis", "Solution Design", "Development", "Quality Assurance", "Client-Centric Approach", "Agile Methodology"]}
        image={developmentImg}
        buttonTitle='Contact Here'
        left={false}
      />

      <Service
        title='Data'
        description='Data service helps your business transform your data into a strategic asset and make smarter decisions. We leverage the full potential of your data to drive growth from building robust data pipelines to delivering advanced analytics solutions.
        Some of the steps in Data process are: Data Discovery, Data Architecture, Implementation, Insights, Optimization.'
        steps={["Data pipelines", "Data warehousing", "Data aquisition", "Data transformation", "Insights"]}
        image={dataImg}
        buttonTitle='Contact Here'
      />

      <Service
        title='Cloud & DevOps'
        description='By integrating cloud computing with modern DevOps practices, we help you build, deploy and manage robust, automated and secure environment for you digital offering. '
        steps={["Cloud Migration", "CI/CD Implementation", "Infrastructure as Code (IaC)", "Monitoring and Logging", "Automation"]}
        image={cloudImg}
        buttonTitle='Contact Here'
        left={false}
      />

      <Service
        title='AI & ML'
        description='Our Artifical Intelligence and Machine Learning service adds an extra layer of intelligence to the Data service. This includes AI roadmap, customized machine learning models. '
        steps={["AI Strategy", "Custom models", "Automations", "Natural Language Processing (NLP)", "Language models", "AI integration"]}
        image={aiImg}
        buttonTitle='Contact Here'
      />

    </div>
  </section>
)

export default DetailedServiceSection;